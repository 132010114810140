import React from "react";
import propTypes from "prop-types";
import { AppStateProvider } from "./state";

const wrapWithState = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};

wrapWithState.propTypes = {
  element: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default wrapWithState;
