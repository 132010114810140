// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-home-home-js": () => import("./../../../src/pageTemplates/Home/Home.js" /* webpackChunkName: "component---src-page-templates-home-home-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/Preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-prismic-dynamic-queries-sales-sheet-query-js": () => import("./../../../src/prismic/dynamicQueries/SalesSheetQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-sales-sheet-query-js" */)
}

