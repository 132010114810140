const reducer = (state, action) => {
  switch (action.type) {
    case "setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };

    default:
      return state;
  }
};

const initialState = {
  previewMode: false,
};

export default {
  reducer,
  initialState,
};
