import React, { createContext, useContext, useReducer } from "react";
import bundleReducer from "./reducer";

/* eslint react/prop-types: 0 */

// This combines React's `context` with the `useReducer` hook to implement global app state.

// We export one function that is used to
// (1) create an AppStateProvider, which is React Context API Provider
// (2) `useAppState` which is a custom hook that can be used to access app state in functional components
// (3) `AppStateConsumer` which is a React Context API Consuemer for accessing state in class-based components

const createAppState = (rawReducer, initialState) => {
  // `bundleReducer` will combine the reducer objects and insitialState
  // and pass them to the React `useReducer` hook. It will also check for
  // any "middleware" functions that need to be run when app state is updated
  const reducer = bundleReducer(rawReducer);

  // Set up a React `context` instance to hold our app state.
  // We're making the default value equal to the apps initialState
  const AppState = createContext([initialState]);

  // Define the top-level wrapper component that provides this context. This should wrap the
  // entire app. In Gatsby, we need to use this component in gatsby-browser.js as we don't otherwise
  // have access to the top-level component in our app.
  const AppStateProvider = ({ children }) => (
    <AppState.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppState.Provider>
  );

  const AppStateConsumer = AppState.Consumer;

  // Create a custom hook which is just a wrapper for the `useContext` hook
  // that specifically loads the AppState
  const useAppState = () => useContext(AppState);

  return {
    AppStateProvider,
    AppStateConsumer,
    useAppState,
  };
};

export default createAppState;
